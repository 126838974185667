$c-primary: #5376ae;
$c-secandary: rgba(15, 137, 40, 0.99);
$dots: false;
$loading-bg-overrided: white;
$progress-bar-overided: rgba(15, 137, 40, 0.99);
$short-loader-dot1: #deb900;
$short-loader-dot2: #f15c3e;
$short-loader-dot3: #21285e;
$short-loader-dot4: #00aeb8;
$base-font-family: 'SourceSansPro', sans-serif;
$font-family-heading: 'SourceSansPro', sans-serif;

      @font-face {
        font-family: 'SourceSansPro';
        src: url('/assets-mobile/fonts/SourceSansPro-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SourceSansPro';
        src: url('/assets-mobile/fonts/SourceSansPro-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'SourceSansPro';
        src: url('/assets-mobile/fonts/SourceSansPro-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SourceSansPro';
        src: url('/assets-mobile/fonts/SourceSansPro-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'SourceSansPro';
        src: url('/assets-mobile/fonts/SourceSansPro-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SourceSansPro';
        src: url('/assets-mobile/fonts/SourceSansPro-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'SourceSansPro';
        src: url('/assets-mobile/fonts/SourceSansPro-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SourceSansPro';
        src: url('/assets-mobile/fonts/SourceSansPro-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
$primary: var(--primary);
.wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: stretch;
  text-align: left;

  .description {
    text-align: left;
    margin-bottom: 1.5rem; //24
  }
  .container {
    max-width: 440px;
    .userDetailRow {
      position: relative;
      & > label {
        &:first-child {
          position: absolute;
          left: 0;
          top: 0;
          width: 35%;
          font-weight: bold;
          font-size: 1rem; //16
          line-height: 1.2;
          z-index: 1;
          height: 3.75rem; //60
          display: flex;
          align-items: center;
        }
      }
      .address-label-span {
        height: 3.75rem; //60
      }
    }
    .check-confirm {
      margin-top: 2.5rem;
      margin-bottom: 1rem;
    }
  }

  .error {
    color: red;
    font-size: 0.75rem; //12
  }

  .input {
    .halfWith {
      max-width: 50%;
      margin-right: 1rem !important;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
