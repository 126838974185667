$c-primary: #5376ae;
$c-secandary: rgba(15, 137, 40, 0.99);
$dots: false;
$loading-bg-overrided: white;
$progress-bar-overided: rgba(15, 137, 40, 0.99);
$short-loader-dot1: #deb900;
$short-loader-dot2: #f15c3e;
$short-loader-dot3: #21285e;
$short-loader-dot4: #00aeb8;
$base-font-family: 'SourceSansPro', sans-serif;
$font-family-heading: 'SourceSansPro', sans-serif;

      @font-face {
        font-family: 'SourceSansPro';
        src: url('/assets-mobile/fonts/SourceSansPro-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SourceSansPro';
        src: url('/assets-mobile/fonts/SourceSansPro-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'SourceSansPro';
        src: url('/assets-mobile/fonts/SourceSansPro-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SourceSansPro';
        src: url('/assets-mobile/fonts/SourceSansPro-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'SourceSansPro';
        src: url('/assets-mobile/fonts/SourceSansPro-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SourceSansPro';
        src: url('/assets-mobile/fonts/SourceSansPro-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'SourceSansPro';
        src: url('/assets-mobile/fonts/SourceSansPro-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SourceSansPro';
        src: url('/assets-mobile/fonts/SourceSansPro-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.checkbox {
  display: none;
}

.option_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  height: 2.8125rem; //45
  margin-bottom: 0.6875rem; //11
  cursor: pointer;
  &.checked {
    background: #f4f4f4;
    border-radius: 10px;
  }

  .check_mark_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 3.3125rem; //53
    height: 2.8125rem; //45

    img {
      width: 1.375rem; //22
      height: auto;
    }
  }

  .option_title {
    flex: 1;
  }
}
